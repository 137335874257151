import {extend} from "./helper/extend";

class StickyHeader
{
    constructor(options)
    {
        this.options = extend(true,
            {
                selector: 'header',
                linkSelector: null,
                scrollSettings: {
                    startHeight: 100,
                    stopHeight: 50,
                    stopPosition: 400,
                    breakPosition: 600,
                },
                classes: {
                    useClasses: false,
                    isOpen: 'sh-open',
                    onStop: 'sh-stop'
                },
                logo: {
                    autoScale: true,
                    selector: null,
                    maxHeight: null,
                    minHeight: null
                },
                responsive: null,
                onInit: () => {},
                onPlay: () => {},
                onPause: () => {},
                onUpdate: () => {}
            }, options || {})

        if (!(this.el = document.querySelector(this.options.selector)))
            return

        // Set Links
        if (this.options.linkSelector)
            this.links = this.el.querySelectorAll(this.options.linkSelector)

        // Set Logo
        if (this.options.logo.selector)
            this.logo = document.querySelector(this.options.logo.selector)

        this.lastScrollY = null
        this.direction = ''
        this.lastDirection = ''
        this.betweenState = false

        this.paused = false
        this.scrollDisabled = false

        // Enum states
        this.state = {
            open: 1,
            hidden: 2
        }

        this._checkResponsiveBreakpoints()
        this._setScrollDirection()
        this._updateHeightAndAnimation()
        this._updateState()
        this._registerEvents()

        this.options.onInit.call(this)
    }

    _checkResponsiveBreakpoints()
    {
        this.responsive = this.options.scrollSettings

        if (!this.options.logo.autoScale && this.options.logo.selector)
        {
            this.responsive = extend(true, this.responsive, {
                logo: {
                    maxHeight: this.options.logo.maxHeight,
                    minHeight: this.options.logo.minHeight
                }
            })
        }

        if (!!this.options.responsive)
        {
            for (let responsiveWidth in this.options.responsive)
            {
                if (window.innerWidth >= responsiveWidth)
                    this.responsive = extend(true, this.responsive, this.options.responsive[responsiveWidth])
            }
        }
    }

    _registerEvents()
    {
        // Scroll event
        window.addEventListener('scroll', () =>
        {
            if (this.paused)
                return

            this._checkLargeScroll()
            this._updateState()
            this._setScrollDirection()

        },{passive: true})

        // Resize event
        window.addEventListener('resize', () =>
        {
            this._checkResponsiveBreakpoints()
            this._updateHeightAndAnimation()

            if (this.paused)
                return

            this._updateState()

        },{passive: true})
    }

    _checkLargeScroll()
    {
        if ((this.lastScrollY - window.scrollY) >= this.responsive.stopHeight) {
            this._setState(this.state.open)
            this._updateHeightAndAnimation()
            this.direction = 'up'
        }
        else if (((this.lastScrollY - window.scrollY) * -1) >= this.responsive.stopHeight)
        {
            this._setState(this.state.hidden)
            this._updateHeightAndAnimation()
            this.direction = 'down'
        }
    }

    _setScrollDirection()
    {
        // Save last direction for check
        this.lastDirection = this.direction

        if (!this.lastScrollY || this.lastScrollY === window.scrollY)
            this.direction = ''

        else if (this.lastScrollY < window.scrollY)
            this.direction = 'down'

        else if (this.lastScrollY > window.scrollY)
            this.direction = 'up'

        this.lastScrollY = window.scrollY
    }

    _getPosition()
    {
        return this.el.offsetTop + (this.lastScrollY - window.scrollY)
    }

    _updateHeightAndAnimation()
    {
        const scrollFactor = window.scrollY / this.responsive.stopPosition
        const updateAnimationDelay = -(scrollFactor) + 's'
        const resetAnimationDelay = -1 + 's'

        if (window.scrollY <= this.responsive.stopPosition)
        {
            this.el.style.height = this.responsive.startHeight - ((this.responsive.startHeight - this.responsive.stopHeight) * (window.scrollY / this.responsive.stopPosition)) + 'px'
            this.el.style.animationDelay = updateAnimationDelay

            if (this.options.linkSelector)
                for (let i = 0; i < this.links.length; i++) {
                    this.links[i].style.animationDelay = updateAnimationDelay
                }

            if(this.options.classes.useClasses)
                this.el.classList.remove(this.options.classes.onStop)
        }
        else
        {
            this.el.style.height = this.responsive.stopHeight + 'px'
            this.el.style.animationDelay = resetAnimationDelay

            if (this.options.linkSelector)
                for (let i = 0; i < this.links.length; i++)
                {
                    this.links[i].style.animationDelay = resetAnimationDelay
                }

            if(this.options.classes.useClasses)
                this.el.classList.add(this.options.classes.onStop)
        }

        if(this.options.logo.selector && this.logo)
            this._updateLogoHeight()

        this.options.onUpdate.call(this, scrollFactor)
    }

    _updateLogoHeight()
    {
        const percentage = window.scrollY * 100 / this.responsive.stopPosition
        const minHeightFactor = this.options.logo.autoScale ? this.responsive.stopHeight / this.responsive.startHeight : this.responsive.logo.minHeight / this.responsive.logo.maxHeight
        const heightDifferenceFactor = 1 - minHeightFactor

        if (window.scrollY <= this.responsive.stopPosition)
            this.logo.style.transform = 'scale(' + (1 - (heightDifferenceFactor * percentage / 100)).toFixed(3) + ')'
        else
            this.logo.style.transform = 'scale(' + minHeightFactor.toFixed(3) + ')';
    }

    _setState(state)
    {
        switch(state)
        {
            case this.state.open:
                this.el.style.top = 0
                break

            case this.state.hidden:
                this.el.style.top = -this.responsive.stopHeight + 'px'
                break
        }

        this._setClasses(state)
    }

    // ClassHandler
    _setClasses(state){
        if(!this.options.classes.useClasses)
            return

        switch(state) {
            case this.state.open:
                this.el.classList.add(this.options.classes.isOpen)
                break

            case this.state.hidden:
                this.el.classList.remove(this.options.classes.isOpen)
                break
        }
    }

    _updateState()
    {
        if (this.betweenState)
            this._betweenStateHandler()

        // Above
        else if (window.scrollY < this.responsive.breakPosition) {
            // Check for animation and resized height only when below breakPosition
            this._updateHeightAndAnimation()
            this._setState(this.state.open)
        }

        // Between
        else if (window.scrollY >= this.responsive.breakPosition && window.scrollY < (this.responsive.breakPosition + this.responsive.stopHeight))
            this.betweenState = true

        // Below break position
        else
        {
            if (this.lastDirection !== this.direction && !!this.lastDirection)
                this.betweenState = true

            else if (this.direction === 'down')
                this._setState(this.state.hidden)

            else if (this.direction === 'up')
                this._setState(this.state.open)

            // This case only happens on initial load
            else if (!this.direction)
            {
                this._setState(this.state.hidden)
                // Set direction down on initial load
                this.direction = 'down'
            }
        }
    }

    _betweenStateHandler()
    {
        const currentPosition = this._getPosition()

        switch(this.direction) {

            case 'up':
                if (currentPosition < 0)
                    this.el.style.top = currentPosition + 'px'
                else
                {
                    this._setState(this.state.open)
                    this.betweenState = false
                }

                break

            case 'down':
                if (currentPosition <= 0 && currentPosition > -this.responsive.stopHeight)
                    this.el.style.top = currentPosition + 'px'
                else
                {
                    this._setState(this.state.hidden)
                    this.betweenState = false
                }
                break
        }
    }

    play()
    {
        if (this.scrollDisabled)
        {
            document.body.style.overflowY = ''
            this.scrollDisabled = false
        }

        this.paused = false
        this._updateHeightAndAnimation()
        this.options.onPlay.call(this)
    }

    stop(open = false, disableScroll = false)
    {
        if (open) {
            this._setState(this.state.open)
            this.direction = 'up'
        }

        if (disableScroll)
        {
            document.body.style.overflowY = 'hidden'
            this.scrollDisabled = disableScroll
        }

        this.paused = true
        this.options.onPause.call(this)
    }

    isPaused()
    {
        return this.paused
    }
}

module.exports = StickyHeader;